import React     from 'react';
import PropTypes from 'prop-types';
import {createUseStyles} from "react-jss";

const useStyles = createUseStyles(theme => ({
	sliderImage: {
		flexBasis: '70%',
		width: '70%',
		padding: 25,
		'& img': {
			width: '100%'
		}
	}
}));

const SliderImage = props => {
	const classes = useStyles();
	return (
		<div className={classes.sliderImage}>
			<img src={props.image} alt={props.imageTitle} />
		</div>
	);
};

SliderImage.propTypes = {
	image: PropTypes.string.isRequired,
	imageTitle: PropTypes.string.isRequired,
};

export default SliderImage;
