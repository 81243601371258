import React, {useState, useEffect} from 'react';
import BackgroundBox                from "./components/BackgroundBox";
import {createUseStyles}            from "react-jss";
import TextButton                   from "./components/TextButton";
import SliderContainer              from "./components/SliderContainer";
import SliderText                   from "./components/SliderText";
import Slider1                      from "./assets/slider1.png";
import Slider2                      from "./assets/slider2.png";
import Slider3                      from "./assets/slider3.png";
import Slider4                      from "./assets/slider4.png";
import Slider5                      from "./assets/slider5.png";
import SliderImage                  from "./components/SliderImage";
const useStyles = createUseStyles(theme => ({
	'@global': {
		'@import': [
			'url("https://fonts.googleapis.com/css?family=Barlow+Semi+Condensed:200,300,300i,400,400i,600")',
		],
		body: {
			backgroundColor: theme.color.grey[900],
		}
	},
	root: {
		fontFamily: theme.typography.fontFamily,
		width: '100vw',
		backgroundColor: theme.color.grey[900],
	},
	content: {
		width: 1200,
		margin: '0 auto',
		position: 'relative',
		zIndex: 1,
	}
}))
const buttons = [
	{
		id: 0,
		label: 'Overview & Steering',
		act: true
	},
	{
		id: 1,
		label: 'Keep Track',
		act: false
	},
	{
		id: 2,
		label: 'Plan & Act',
		act: false
	},
	{
		id: 3,
		label: 'Motivate',
		act: false
	},
	{
		id: 4,
		label: 'Top Performance',
		act: false
	},
]

const slidesEn = [
	{
		id: 0,
		title: 'Overview & Steering',
		text: 'Intuitive UI, OKR overview, Key Results only view, all values on one view',
		link: null,
		image: Slider1,
		imageTitle: ''
	},
	{
		id: 1,
		title: 'Keep Track',
		text: 'See status of Key Results, flag Key Results of your interest, get response from owner',
		link: null,
		image: Slider2,
		imageTitle: ''
	},
	{
		id: 2,
		title: 'Plan & Act',
		text: 'Tasks, Team-Board view, calendar view, One-Pager system',
		link: null,
		image: Slider3,
		imageTitle: ''
	},
	{
		id: 3,
		title: 'Motivate',
		text: 'Intuitive point system with Ranking and Leaderboard',
		link: null,
		image: Slider4,
		imageTitle: ''
	},
	{
		id: 4,
		title: 'Top Performance',
		text: 'Achieved Report - direct feedback to see what is Achieved',
		link: null,
		image: Slider5,
		imageTitle: ''
	},
]
const slidesDe = [
	{
		id: 0,
		title: 'Overview & Steering',
		text: 'Intuitives UI, OKR Übersicht, Key Results Übersicht, alle Werte auf einen Blick',
		link: null,
		image: Slider1,
		imageTitle: ''
	},
	{
		id: 1,
		title: 'Keep Track',
		text: 'Den Status jedes Key Results im Blick, Markier möglichkeit für Key Results zur Verfolgung, direktes Feedback vom Key Result Besitzer',
		link: null,
		image: Slider2,
		imageTitle: ''
	},
	{
		id: 2,
		title: 'Plan & Act',
		text: 'Tasks, Team planungs Board, Kalender Ansicht, One Pager System für direktes Feedback!',
		link: null,
		image: Slider3,
		imageTitle: ''
	},
	{
		id: 3,
		title: 'Motivate',
		text: 'Intuitives Punktesystem mit Ranglisten!',
		link: null,
		image: Slider4,
		imageTitle: ''
	},
	{
		id: 4,
		title: 'Top Performance',
		text: 'Achieved Report - schneller Überblick über alles was erreicht wurde!',
		link: null,
		image: Slider5,
		imageTitle: ''
	},
]

function App() {
	const classes = useStyles();
	const [button, setButton] = useState(buttons);
	const [stopAni, setStopAni] = useState(false);
	const [activeSlide, setActiveSlide] = useState(0)
	const handleButtonClick = (id) => {
		let newActive = [];
		button.forEach(item => {
			item.act = item.id === id;
			newActive.push(item);
		});
		setButton(newActive);
		setActiveSlide(id)
	};
	const handleStop = () => {
		setStopAni(true);
	}
	useEffect(() =>{
		if (!stopAni) {
			let actIndex = button.findIndex(f => (f.act));
			setTimeout(() => {
				if (actIndex < button.length + 1) {
					handleButtonClick(actIndex > 3 ? 0 : actIndex + 1)
				}
			}, 5500)
		} else {
			handleStop();
		}
	})

	const slides = slidesDe;

  return (
    <div className={classes.root}>
			<BackgroundBox />
			<div className={classes.content}>
				<TextButton onClick={handleButtonClick} stop={handleStop} buttons={button}>
					Overview &amp; Steering
				</TextButton>
					{slides.map((item, index) => (
				<SliderContainer key={index} active={item.id === activeSlide}>
						<SliderText title={item.title} text={item.text} link={item.link} />
						<SliderImage image={slides[activeSlide].image} imageTitle={slides[activeSlide].title} />
				</SliderContainer>
					))}
			</div>
    </div>
  );
}

export default App;
