import React from 'react';
import PropTypes from 'prop-types';
import {createUseStyles} from "react-jss";


const useStyles = createUseStyles(theme => ({
	textButton: {
		listStyle: 'none',
		display: 'flex',
		marginTop: -100,
		'& .button': {
			'& span': {
				color: theme.color.white,
				fontSize: 24,
				marginRight: 50,
				opacity: 0.55,
				transition: 'opacity 0.35s linear',
				cursor: 'pointer',
				'&:hover': {
						opacity: 1,
				}
			},
			'&.act': {
				'& span': {
					opacity: 1,
				}
			},
		},
		'& .btn-0': {
			marginTop: -20,
		},
		'& .btn-1': {
			marginTop: -80,
		},
		'& .btn-2': {
			marginTop: -130,
		},
		'& .btn-3': {
			marginTop: -180,
		},
		'& .btn-4': {
			marginTop: -220,
		},
	}
}))
const TextButton = props => {
	const classes = useStyles();
	const {buttons, onClick, stop} = props;
	return (
		<ul className={classes.textButton}>
			{buttons.map((button, index) => (
				<li key={index + button.id}
						className={button.act ? 'button act btn-' + button.id : 'button btn-' + button.id}>
					<span onClick={() => {onClick(button.id); stop()}}>{button.label}</span>
				</li>
			))}
		</ul>
	);
};

TextButton.propTypes = {
	buttons: PropTypes.array.isRequired,
	onClick: PropTypes.func.isRequired,
	stop: PropTypes.func.isRequired,
};

export default TextButton;
