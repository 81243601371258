import React     from 'react';
import PropTypes from 'prop-types';
import {createUseStyles} from "react-jss";

const useStyles = createUseStyles(theme => ({
	sliderContainer: {
		display: 'flex',
		color: theme.color.white,
		position: 'absolute',
		width: '100%',
		'&.act': {
			right: 0,
			visibility: 'visible',
			opacity: 1,
			transition: 'all 1.5s ease-in',
		},
		'&.hide': {
			right: '-100vw',
			visibility: 'hidden',
			opacity: 0,
			transition: 'all 1.5s ease-out',
		}
	}
}));

const SliderContainer = props => {
	const classes = useStyles();
	return (
		<div className={props.active ? classes.sliderContainer + ' act' : classes.sliderContainer + ' hide'}>
			{props.children}
		</div>
	);
};

SliderContainer.propTypes = {
	children: PropTypes.node.isRequired,
	active: PropTypes.bool,
};

export default SliderContainer;
