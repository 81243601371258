import React             from 'react';
import {createUseStyles} from "react-jss";
import headerImg from '../assets/home-header.jpg';

const useStyles = createUseStyles(theme => ({
	backgroundRoot: {
		width: '100%',
		height: 800,
		position: 'relative',
		'& .background-image': {
			width: '100%',
			height: '100%',
			backgroundImage: `url(${headerImg})`,
			backgroundPosition: 'center 0px',
			backgroundSize: 4500,
			backgroundRepeat: 'no-repeat',
			position: 'absolute',
			top: 0,
			zIndex: 0
		}
	},
	'@media (max-width: 1440px)': {
		backgroundRoot: {
			'& .background-image': {
				backgroundSize: 3500,
			}
		}
	}
}))

const BackgroundBox = props => {
	const classes = useStyles();
	return (
		<div className={classes.backgroundRoot}>
			<div className={'background-image'} />
		</div>
	);
};


export default BackgroundBox;
