export const theme = {
	color: {
		white: '#fff',
		black: '#000',
		mainBlue: '#305165',
		lightBlue: '#5b9cd5',
		lighterBlue: '#aad0f1',
		grey: {
			100: '#f4f4f4',
			200: '#efefef',
			300: '#e4e4e4',
			400: '#d9d9d9',
			500: '#c1c1c1',
			600: '#a6a6a6',
			700: '#707070',
			800: '#555555',
			900: '#323232'
		}
	},
	typography: {
		fontFamily: ['Barlow Semi Condensed', 'sans-serif'].join(','),
	}
}
