import React             from 'react';
import PropTypes         from 'prop-types';
import {createUseStyles} from "react-jss";

const useStyles = createUseStyles(theme => ({
	sliderTextContainer: {
		flexBasis: '30%',
		width: '30%',
		padding: 25,
		'& h1': {
			fontSize: 30,
		},
		'& p': {
			margin: '20px 0',
			fontSize: 24
		},
		'& .button': {
			padding: '5px 10px',
			border: '1px solid ' + theme.color.white,
			borderRadius: 15,
			color: theme.color.white,
			textDecoration: 'none',
			margin: '20px 0',
			display: 'inline-block',
			'&:hover': {
				backgroundColor: theme.color.grey[800],
			}

}
	}
}));

const SliderText = props => {
	const classes = useStyles();
	const {title, text, link} = props;
	return (
		<div className={classes.sliderTextContainer}>
			<h1>{title}</h1>
			<p>{text}</p>
			{link &&
			<a className={'button'} href={link}>Mehr erfahren</a>
			}
		</div>
	);
};

SliderText.propTypes = {
	title: PropTypes.oneOfType([PropTypes.string, PropTypes.node,]).isRequired,
	text: PropTypes.oneOfType([PropTypes.string, PropTypes.node,]).isRequired,
	link: PropTypes.string,
};

export default SliderText;
