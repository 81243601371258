import React              from 'react';
import ReactDOM           from 'react-dom';
import 'normalize.css/normalize.css';
import App                from './App';
import * as serviceWorker from './serviceWorker';
import {ThemeProvider}    from "react-jss";
import {theme}            from "./styles/theme";



ReactDOM.render(
  <React.StrictMode>
		<ThemeProvider theme={theme}>
    	<App />
		</ThemeProvider>
  </React.StrictMode>,
  document.getElementById('rootDe')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
